import styled from 'styled-components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Notification as NotificationOrigin} from '@imperva/basic-components';
import {MFA_TYPES} from '../../../../shared/constants';

const Notification = styled(NotificationOrigin)`
	${({hasMergeTop}) => hasMergeTop && 'margin-top: 20px;'}
	/* margin-bottom: 24px; */
	a {
		text-decoration: underline !important;
	}
`;

const GOOGLE_FACTOR_NOTIFICATION_LS_KEY = 'dismiss-google-mfa-notification';

export const GoogleFactorNotification = ({factors = [], hasMergeTop}) => {
	const {t} = useTranslation();
	const dismissed = localStorage.getItem(GOOGLE_FACTOR_NOTIFICATION_LS_KEY) === 'true';
	const [displayNotification, setDisplayNotification] = useState(false);
	//If the user has some factor that is not supported in MY OR can't be migrated to Okta, it means that the user already change the 2FA configuration,
	// and hence it's no longer necessary to show him the message
	const hasNewFactor = useMemo(() => !!factors.find((item) => {
		const factor = item.factorType?.toLowerCase();
		return factor !== MFA_TYPES.sms && factor !== MFA_TYPES.email;
	}), [factors]);

	const handleHideNotification = useCallback(() => {
		setDisplayNotification(false);
		localStorage.setItem(GOOGLE_FACTOR_NOTIFICATION_LS_KEY, 'true');
	}, []);

	useEffect(() => {
		if (dismissed || hasNewFactor)
			return;

		setDisplayNotification(true);
	}, [dismissed, hasNewFactor]);

	if (!displayNotification)
		return null;

	return (
		<Notification
			linkButton={{
				action: handleHideNotification,
				text: 'Don\'t show this message again',
			}}
			hasMergeTop={hasMergeTop}
			shadow={true}
			showCloseButton={false}
			kind='info'
			// title={t('sso.ssoMigration.title')}
			subtitle={t('mfa.googleMfaNotification.text')}
		/>
	);
};