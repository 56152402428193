import React, {useEffect, useCallback, useState} from 'react';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {BackLink} from '../../../../shared';
import * as selectors from '../../../../store/selectors';
import {history} from '../../../../utils';
import {ExpiredSessionNote} from '../ExpiredSessionNote';
import {Login} from '../Login';
import {FactorItem} from './FactorItem';
import {GoogleFactorNotification} from './GoogleFactorNotification';

export const LoginWithOktaMfa = () => {
	const [isExpired, setIsExpired] = useState(false);
	const {t} = useTranslation();
	const {data: signinData} = useSelector(selectors.oktaSigninWithCredentials);

	const handleBackToLogin = useCallback(() => {
		history.push('/login');
	}, []);

	useEffect(() => {
		if (isEmpty(signinData))
			history.push('/login');
	}, [signinData]);

	if (isEmpty(signinData)) {
		return null;
	}

	return (
		<Login
			headerText={t('login.header')}
		>
			<BackLink
				text={t('login.backToLogin')}
				onClick={handleBackToLogin}
				role='button'
				data-test-label='Back to login'
			/>
			{signinData.factors.map(item => (
				<FactorItem
					key={item.id}
					expiresAt={signinData.expiresAt}
					isExpired={isExpired}
					setIsExpired={setIsExpired}
					{...item}
				/>
			))}
			{isExpired && <ExpiredSessionNote expirationText={'login.sessionExpired1'} />}
			<GoogleFactorNotification
				hasMergeTop={isExpired}
				factors={signinData.factors}
			/>
		</Login>
	);
};